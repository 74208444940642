import React, { useState, useMemo, useEffect } from 'react';
import { Row, Col, Input, FormGroup, ButtonToolbar, ButtonGroup, Button } from "reactstrap";
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";
import { useJnx } from "../../util/jnx";
import { useSideChannelSubscription } from "../../util/useSideChannel";

export default function Counter(props) {
    const {
        required: propRequired,
        disabled,
        readonly: propReadonly,
        onChange: onChangeForm,
        idSchema: { $id },
        schema: { title, defaultValue: propDefaultValue },
        uiSchema: {
            'ui:readonly': uiReadonly,
            'akc:requiredIfVisible': requiredIfVisible
        },
        formContext,
        formData,
    } = props;

    const rootFormData = useSideChannelSubscription(formContext.sideChannel, 0);

    const defaultValueJnx = useJnx(propDefaultValue);

    const [defaultValue, setDefaultValue] = useState(1);

    useEffect(() => {
        let newDefaultValue;

        if (formData) {
            newDefaultValue = formData;
        } else if (propDefaultValue === undefined) {
            newDefaultValue = 1;
        } else if (typeof propDefaultValue === "number") {
            newDefaultValue = propDefaultValue >= 1 ? propDefaultValue : 1;
        } else {
            const val = defaultValueJnx && defaultValueJnx.eval(rootFormData || {}, '', {
                rootFormData,
                formContext,
            });
        
            newDefaultValue = val !== undefined && val !== null ? parseInt(val, 10) : 1;
        }
    
        setDefaultValue(newDefaultValue);

    }, [formData, propDefaultValue, defaultValueJnx, rootFormData, formContext]);

    const readonly = uiReadonly || propReadonly;
    const required = !!propRequired || !!requiredIfVisible

    const handleClick = () => {
        setDefaultValue('');
    };

    return (
        <FormGroup fluid>
            {
                title.trim() ? (
                    <label className="control-label" htmlFor={$id}>
                        {title}{required ? <span className="required">*</span> : null}
                    </label>
                ) : null
            }
            {/* The noGutters prop is NECESSARY to keep uniform component width... */}
            <Row
                noGutters
                style={{
                    minWidth: "10rem",
                    maxWidth: "15rem"
                }}
            >
                <Col>
                    <Input
                        type="text"
                        value={defaultValue}
                        onClick={handleClick}
                        onChange={(e) => {
                            const v = parseInt(e.target.value, 10);
                            if (v === 0) {
                                return; // Ignore changes if the value is zero
                            }
                            if (v >= 0) {
                                onChangeForm(v);
                            }
                        }}
                    />
                </Col>
                <Col xs="5">
                    <ButtonGroup >
                        <Button
                            disabled={readonly || disabled}
                            onClick={() => onChangeForm(formData + 1)}
                        >
                            <i className="fa fa-plus" />
                        </Button>
                        <Button
                            disabled={readonly || disabled}
                            onClick={() => onChangeForm((formData - 1 > 0) ? formData - 1 : 1)}
                        >
                            <i className="fa fa-minus" />
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </FormGroup>

    );
}