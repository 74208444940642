import Color from "color";

/**
 * A helper to derive a black or white text color based on the brightness of a background color.
 * 
 * @param {string} bgColor - A css color string representing a background color.
 * @returns {string} - A hex-format css color string representing a font color.
 */
const getContrastTextColor = (bgColor) => {
  try {
    // Parse RGB
    const color = Color(bgColor);
    const {r, g,  b} = color.object();
  
    // Use formula to determine contrast
    if ((r*0.299 + g*0.587 + b*0.114) > 150) {
      return '#000000';
    } else {
      return '#FFFFFF';
    }
  }
  catch (err) {
    console.log("Error when parsing color, returning default: ", err);
    return '#000000';
  }
};

/**
 * A helper to blend two colors
 * 
 * @param {string} color1 - A hex format string representing a color.
 * @param {string} color2 - A hex format string representing another color.
 * @param {number} t - A number between 0 and 1 to linearly interpolate between colors (default = 0.5)
 * @returns {string} - A hex format string representing a font color.
 */
const getBlendedColor = (color1, color2, t = 0.5) => {
  const {r: r1, g: g1, b: b1} = Color(color1.toLowerCase()).object();
  const {r: r2, g: g2, b: b2} = Color(color2.toLowerCase()).object();
  return Color({
    r: (r1*t) + (r2*(1-t)),
    g: (g1*t) + (g2*(1-t)),
    b: (b1*t) + (b2*(1-t)),
  }).hex();
};

export { getContrastTextColor, getBlendedColor }