// TODO: Figure out why it is not saving the lat and long in shiftInstance's address field when submitting. Only stores the address.

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Input, FormGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { GOOGLE_MAP_API } from "../../constants";

import { useJnx } from "../../util/jnx";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import getPathFromId from "../../util/getPathFromId";

const containerStyle = {
  width: "100%",
  height: "400px"
};

const mapCenter = {
  lat: 18.2208,
  lng: -66.5901
};

function debounce(func, wait) {
  let timeout;
  return function(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const AddressMapWidget = ({
  formContext,
  formData,
  onChange,
  idSchema: { $id },
  schema,
  uiSchema: {
    "ui:readonly": uiReadonly,
    "akc:requiredIfVisible": requiredIfVisible
  },
  readonly: propReadonly,
  disabled: propDisabled,
  required: propRequired
}) => {
  const { 
    title, 
    properties,
    jsonata: jsonataComputation,
    onUpdate
  } = schema;

  const {
    sideChannel,
    bindings: fcBindings,
    setFormDataValues,
  } = formContext;

  const {
    address: propDefaultAddress,
    latitude: propDefaultLatitude,
    longitude: propDefaultLongitude
  } = properties || {};

  const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}, {}];
  const [address, setAddress] = useState(typeof formData === 'object' ? formData.address : formData || "");
  const [markerPosition, setMarkerPosition] = useState({
    lat: mapCenter.lat,
    lng: mapCenter.lng
  });
  const [apiLoaded, setApiLoaded] = useState(false);
  const [showIconFlag, setShowIconFlag] = useState(false);

  const readonly = propReadonly || uiReadonly;
  const disabled = propDisabled || readonly;
  const required = !!propRequired || !!requiredIfVisible;

  const bindings = useMemo(() => ({
    ...fcBindings,
    formObject,
    formContext
  }), [fcBindings, formObject, formContext]);

  const path = useMemo(() => getPathFromId($id), [$id]);
  const valueJnx = useJnx(jsonataComputation);

  const functionBinds = useMemo(() => ({
    set: setFormDataValues,
    log: (...args) => console.log("jsonata console log\n", ...args)
  }), [setFormDataValues]);

  const onUpdateJnx = useJnx(onUpdate, { functionBinds });
  const defaultAddressJnx = useJnx(propDefaultAddress?.jsonata?.expr);

  useEffect(() => {
    if (propDefaultAddress?.jsonata?.expr) { // ONLY RUNS IF ADDRESS PROPERTY CONTAINS A JSONATA EXPRESSION
      const context = { ...rootFormData };
      const refValue = defaultAddressJnx.eval(context, path, {...bindings, lastValue: formData});
      setAddress(refValue);
      onChange({
        address: refValue,
        latitude: markerPosition.lat,
        longitude: markerPosition.lng
      });
      if(refValue !== formObject?.data?.shift?.data?.location?.data?.address?.address){
        setShowIconFlag(true);
      }
    }
  },[defaultAddressJnx, formContext])

  useEffect(() => {
    formData = (typeof formData === 'object') ? formData : {
      'address': formData,
      'latitude': Number(formObject.metadata.shiftLatitude),
      'longitude': Number(formObject.metadata.shiftLongitude)
    }
    if (valueJnx) {
      try {
          const context = { ...rootFormData };
          const refValue = valueJnx.eval(context, path, {...bindings, lastValue: formData});
          if (refValue.address !== formData?.address) {
              setAddress(formData?.address || formData?.address === '' ? formData?.address : refValue);
              onChange({
                address: formData?.address || formData?.address === '' ? formData?.address : refValue,
                latitude: markerPosition.lat,
                longitude: markerPosition.lng
              });
              if (onUpdateJnx) setTimeout(() => {
                  onUpdateJnx.evalAsync(rootFormData, path, { ...bindings, value: refValue });
              }, 100);
          }
      } catch (e) {
          console.log("Computed Field error");
          console.error(e);
      }
    }
    if (
      formData?.address !== undefined &&
      formData?.address !== null &&
      formData?.address !== address
    ) {
      setAddress(formData?.address || "");
    }
    if (
      Number(formObject?.metadata?.shiftLatitude) !== markerPosition.lat ||
      Number(formObject?.metadata?.shiftLongitude) !== markerPosition.lng
    ) {
      setMarkerPosition({
        lat: markerPosition.lat || mapCenter.lat,
        lng: markerPosition.lng || mapCenter.lng
      });
    }
  }, [formData]);

  const debouncedGeocodeAddress = useCallback(
    debounce(address => {
      if (address && !readonly && apiLoaded) {
        geocodeAddress(address);
      }
    }, 500),
    [readonly, apiLoaded]
  );

  useEffect(() => {
    debouncedGeocodeAddress(address);
  }, [address, debouncedGeocodeAddress]);

  const geocodeAddress = address => {
    if (!window.google || !window.google.maps) {
      console.error("Google Maps API is not loaded yet.");
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK") {
        const location = results[0].geometry.location;
        setMarkerPosition({
          lat: location.lat(),
          lng: location.lng()
        });
        onChange({
          address,
          latitude: location.lat(),
          longitude: location.lng()
        });
      } else {
        console.error(
          `Geocode was not successful for the following reason: ${status}`
        );
      }
    });
  };

  const reverseGeocode = (lat, lng) => {
    if (!window.google || !window.google.maps) {
      console.error("Google Maps API is not loaded yet.");
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat, lng };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK" && results[0]) {
        const newAddress = results[0].formatted_address;
        setAddress(newAddress);
        onChange({
          address: newAddress,
          latitude: lat,
          longitude: lng
        });
      } else {
        console.error(
          `Reverse geocode was not successful for the following reason: ${status}`
        );
      }
    });
  };

  const handleAddressChange = event => {
    if (!readonly) {
      setAddress(event.target.value);
      onChange({
        address: event.target.value,
        latitude: markerPosition.lat,
        longitude: markerPosition.lng
      });
    }
  };

  const handleMarkerDragEnd = e => {
    if (!readonly) {
      const newLat = e.latLng.lat();
      const newLng = e.latLng.lng();
      setMarkerPosition({ lat: newLat, lng: newLng });
      reverseGeocode(newLat, newLng);
    }
  };

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleApiLoad = () => {
    setApiLoaded(true);
  };

  return (
    <FormGroup>
      {title && (
        <label className="control-label" htmlFor={schema.title}>
          {title}
          {required && <span className="required">*</span>}
        </label>
      )}
      <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
      <Input
        type="text"
        value={address}
        onChange={handleAddressChange}
        onKeyDown={handleKeyDown}
        placeholder="Enter address"
        style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
        disabled={disabled}
        readOnly={readonly}
        required={required}
      />
      {/* Conditionally render the icon based on the showIconFlag */}
      {showIconFlag && (
       <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              The address was edited.
            </Tooltip>
          }
        >
          <span className="ml-2">
            <FontAwesomeIcon icon={faExclamationCircle} color="orange" size="lg" />
          </span>
        </OverlayTrigger>
      )}
    </div>
      <LoadScript googleMapsApiKey={GOOGLE_MAP_API} onLoad={handleApiLoad}>
        {apiLoaded && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={markerPosition}
            zoom={10}
          >
            <Marker
              position={markerPosition}
              onDragEnd={handleMarkerDragEnd}
              draggable={!readonly}
            />
          </GoogleMap>
        )}
      </LoadScript>
    </FormGroup>
  );
};

export default AddressMapWidget;
