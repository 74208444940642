import { useRef, useState, useContext } from "react"
import { FilterForm as BaseFilterForm  } from "../../FormComponent";
import { AuthContext } from '../../../context/AuthContext';

/** 
 * A module containing a component for rendering and using filters through an UWE form.
 * 
 * This is a standalone equivalent of what is used in the WorkTray dashboard, to be used for other dashboards.
 *
 * @module components/Dashboard/util/FilterForm
 */

/** 
 * At the moment, imports `parseSearchFormDefinition` from WorkTray. Ideally, it should be backwards;
 * the functions should be defined here, and used in the WorkTray dashboard.
 * 
 * Returns a tuple containing the formDefinition and the parsed filters.
 * 
 * @todo Add filter validation, like in the `useFilters` hook.
 */
import { parseSearchFormDefinition } from "../WorkTray"

/**
 * @callback FilterFormOnChange
 * @param {Object} formData - An object containing the current form data.
 */

/**
 * A component for rendering and using filters through an UWE form, to be used in dashboards.
 * 
 * @param {Object} props
 * @param {Object} props.element - The object with dashboard configurations.
 * @param {FilterFormOnChange} props.onChange - The callback invoked to use the formData.
 */
const FilterForm = (props) => {
    const { element, onChange, formData } = props;

    // Parse formDefinition, filters
    const [formDefinition, filters] = parseSearchFormDefinition(element);

    /** 
     * Construct formContext 
     * @todo Make a useFormContext hook to construct this? This one is for FilterForm, which should be smaller?
     */
    const auth = useContext(AuthContext);
    const formContext = { auth };

    // A state to keep track of 
    const [filterValues, setFilterValues] = useState(formData)

    // Ref for the form component (unused)
    const formRef = useRef()

    // A callback to update the form data.
    const onSearch = (update) => {
        const newFilterValues = {
            ...filterValues,
            ...update.formData,
        };
        setFilterValues(newFilterValues);
        onChange?.(newFilterValues);
    }
    
    return (
        <BaseFilterForm
            className="worktray-filters"
            tagName="div"
            ref={formRef}
            formData={filterValues}
            formContext={formContext}
            {...formDefinition}
            onChange={onSearch}
        />
    );
}

/**
 * An object containing the subschema for a dashboard element to specify filters,
 * to be used with the FilterForm component.
 * 
 * @type {Object}
 * @mixin
 */
const filterFormPropertySchema = {
    "filters": {
        "title": "Filter Form Configuration",
        ":classNames": "from-col-1-size-3",
        "type": "array",
        "map:array": "filters",
        "items": {
            "type": "object",
            "properties": {
                "key": {
                    "type": "string",
                    "title": "key",
                    "map:field": "key"
                },
                "title": {
                    "type": "string",
                    "title": "title",
                    "map:field": "title"
                },
                "ui:field": {
                    "type": "string",
                    "title": "Tipo de Campo",
                    "map:field": "ui:field"
                },
                "lookup": {
                    "type": ["string", "object"],
                    "ui:field": "JsonField",
                    "title": "lookup",
                    "map:field": "lookup"
                },
                "type": {
                    "type": "string",
                    "title": "type",
                    "map:field": "type"
                },
                "format": {
                    "type": "string",
                    "title": "format",
                    "map:field": "format"
                },
                "map:field": {
                    "type": ["string", "object"],
                    "ui:field": "JsonField",
                    "title": "map:field",
                    "map:field": "map:field"
                },
                "categoriesSelectable": {
                    "type": "boolean",
                    "title": "categoriesSelectable",
                    "map:field": "categoriesSelectable"
                }
            }
        }
    },
};


export {
    FilterForm,
    filterFormPropertySchema
};
