import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap";

const PARAMS = [
    "title", "text", "options", "extraProps"
];

const DEFAULT_OPTIONS = [
    { text: "Confirm", color: "primary", value: true },
    { text: "Back", color: "secondary", value: false },
];


function ConfirmationModal({
    title,
    text,
    options = DEFAULT_OPTIONS,
    // formData: rootFormData,
    onConfirm: onClose,
    extraProps: {reversible} = {},
    isOpen
}) {


    return (
        <Modal isOpen={isOpen}
            toggle={() => onClose()}
            className="modal-primary"
            size='sm'
            backdrop
        >
            <ModalBody>
                <div className="textModalConfirm">
                    {text}
                </div>
                {!reversible ? (<div className="textBottomModalConfirm">
                    This action cannot be undone.
                </div>) : null}
            </ModalBody>
            <ModalFooter className="justify-content-center">{options.map(({ text, color, onClick, value }, idx) => (
                <Button key={idx}
                    onClick={() => onClick ? onClick({ closeModal: onClose }) : onClose(value)}
                    color={color || "secondary"}
                >{text || `Option ${idx}`}</Button>
            ))}
            </ModalFooter>
        </Modal>
    );
}


ConfirmationModal.PARAMS = PARAMS;


export default ConfirmationModal;