import React, { useState, useEffect } from "react";
import { Calendar, dayjsLocalizer  } from 'react-big-calendar';
import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = dayjsLocalizer(dayjs);

function CalendarView(props) {
    const {
        schema: {
            title,
            lookup,
            type
        },
        formContext,
        formData,
        idSchema: { $id } = {},
    } = props;
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const fetchEvents = async () => {
        try {  
            const currentDate = new Date();
            const nextMonthDate = new Date(currentDate);
            nextMonthDate.setMonth(currentDate.getMonth() + 1);
            const previousMonthDate = new Date(currentDate);
            previousMonthDate.setMonth(currentDate.getMonth() - 1);
            console.log("Calendar formData:", formData); // email del usuario
            const url = `https://windmar-backend.akcelita.com/api/getCalendarEventsUsingEmail?email=${formData}&startDate=${previousMonthDate.toISOString()}&endDate=${nextMonthDate.toISOString()}&filter=''`;
            const response = await fetch(url);
            const data = await response.json();
            const newEvents = data.value.map((event, idx)=>{
                console.log("event.subject",event.subject);
                return {
                    id: idx+1,
                    title: event.subject,
                    start: new Date(event.start.dateTime.concat("", event.start.timeZone === 'UTC'?'Z':' GMT-0400')),
                    end: new Date(event.end.dateTime.concat("", event.end.timeZone === 'UTC'?'Z':' GMT-0400')),
                }
            })
            setEvents(newEvents);
        } catch (error) {
            console.error('Error fetching users:', error);
            setError(JSON.stringify(error));
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        if(formData){
            setError("");
            fetchEvents();
        }
        else {
            setError("No Email available for the user");
        }
    }, [formData]);
    
    const myEventsList = [
        {
            id: 1,
            title: 'Long Event',
            start: new Date(2024, 6, 7),
            end: new Date(2024, 6, 10),
        },
        
        {
            id: 2,
            title: 'DTS STARTS',
            start: new Date(2024, 6, 12, 8, 0, 0),
            end: new Date(2024, 6, 12, 12, 0, 0),
        },
        
        {
            id: 3,
            title: 'DTS ENDS',
            start: new Date(2024, 6, 12, 4, 0, 0),
            end: new Date(2024, 6, 12, 6, 0, 0),
        },
    ];
    
    if (loading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error Loading the data</div>;
    }
    return (
        
        <div >
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{height: '500px'}}
                defaultView='week'
            />
        </div>
    );
}

export default CalendarView;