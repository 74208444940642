import React from "react"


function SortButton({
    tag = 'button',
    sortKey,
    sortBy,
    setSortBy,
    style,
    children
}) {
    const descSortKey = `-${sortKey}`;
    const Tag = tag;
    const sorting = (sortBy === sortKey ? 'asc' : (sortBy === descSortKey ? 'desc' : null));
    const chevron = ({ asc: 'glyphicon glyphicon-chevron-up', desc: 'glyphicon glyphicon-chevron-down' })[sorting];
  
    return (
      <Tag
        className="sort-button"
        style={style}
        onClick={() => setSortBy(sorting === 'asc' ? descSortKey : sortKey)}
      >
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <span>{children}</span>
          {chevron ? (<i style={{ fontSize: 8, top: -1, paddingLeft: 2 }} className={chevron} />) : null}
        </span>
      </Tag>
    )
}


export default SortButton;