import React, { useState } from "react";
import { Button } from "reactstrap";
import AdjusterEdge from "./AdjusterEdge";

function SidebarPanel({
    title,
    maxHeight,
    adjustable,
    collapsable,
    buttons,
    children,
    sidebarChildCount
}){
    const [collapsed, setCollapsed] = useState();
    const hasButtons = buttons || collapsable
    const [height, setHeight] = useState(maxHeight);
    const hasSetHeight = (sidebarChildCount > 1) && (height !== undefined);

    return (
        <div className="sidebar-panel">
            <div className="titleComponents">
                {title}
                {hasButtons ? (
                    <div className="buttons">
                        {buttons}
                        {collapsable ? (
                            <Button color="clear" onClick={() => setCollapsed(!collapsed)}>
                                <i className={collapsed ? 'fa fa-caret-up': 'fa fa-caret-down'} />
                            </Button>
                        ): null}
                    </div>)
                : null}
            </div>
            {collapsed ? null : (<div className="divComponents" style={
                hasSetHeight ? {overflowX: 'hidden', overflowY: 'auto', height: `${height}px`} : {}
            }>
                {children}
            </div>)}
            {adjustable ? <AdjusterEdge visible fromBottom position={height} setPosition={setHeight} /> : null}
        </div>
    );
}

SidebarPanel.defaultProps = {
    view: 'sidebar'
}

export default SidebarPanel;
