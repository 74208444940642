
import React from 'react';
import {
    InputGroup,
    InputGroupText,
    Input
} from 'reactstrap';
import '../../scss/_searchbar.scss'

const SearchBarInput = ({
    className,
    onChange,
    placeholder
}) => {
    return (
      <div className={`${className} search-bar-input`}>
        <InputGroup>
          <InputGroupText className="">
            <i className="fa fa-search" />
          </InputGroupText>
          <Input
            className="shadow-none"
            placeholder={placeholder}
            onChange={e => {
              e.persist()
              onChange(e.target.value)
            }}
          />
        </InputGroup>
      </div>
    )
}

export default SearchBarInput;
