import React, { useMemo, useEffect, useState, useCallback } from "react";
import { t } from "@lingui/macro"
import {
    FormGroup,
    Input,
    InputGroup,
    UncontrolledDropdown,
    DropdownItem,
    DropdownMenu,
    InputGroupText,
    DropdownToggle,
 } from "reactstrap";

import Notification from "../Notification";
import Loader from "../Loader";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import { concatenatePaths } from "../../util/mapObject";
import getPathFromId from "../../util/getPathFromId";
import { useJnx } from "../../util/jnx";
import useOptionsLookup from "./hooks/useOptionsLookup";
import { makeTreeNode, organizeTreeNodes } from "../CollapsableTreeSelect";
import { convertIfNumber } from "../JsonTreeEditor/util";

const NONE_VALUE = '--None--';

function LookupFilterField(props) {
    const {
        formData,
        formContext,
        formContext: {
            sideChannel
        },
        disabled,
        readonly: propReadonly,
        schema: {
            title,
            lookup,
            categoriesSelectable = false,
        },
        idSchema: { $id } = {},
        uiSchema: {
            'ui:readonly': uiReadonly,
        },
        onChange,
    } = props;

    const readonly = propReadonly || uiReadonly;
    const dataPath = useMemo(() => getPathFromId($id), [$id]);
    const rootFormData = useSideChannelSubscription(sideChannel, 0);

    const {
        options: lookupOptions,
    } = useOptionsLookup({
        lookup,
        rootFormData,
        path: dataPath,
        formContext
    });

    const [options, optionsById] = useParsedOptions(lookupOptions, lookup);
    const isDisabled = readonly || disabled;

    const currentValue = useMemo(() => {
        if (formData) return optionsById[formData]
        return null
    }, [formData, optionsById])

    const renderOption = (option, ix, depth=0) => {
        if (option.children && option.children.length > 0) {
            const category = <small style={{ paddingLeft: 8 * depth}}>{option.label}</small>;
            return (
                <div className="filter-dropdown-group">
                    {categoriesSelectable
                        ? <DropdownItem
                            key={`${option.id}-${ix}`}
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            onClick={() => onChange(option.id)}
                        >
                            {category}
                        </DropdownItem>
                        : <div style={{ padding: "0 1.5rem" }}>{category}</div>
                    }
                    {option.children.map((o, jx) => renderOption(o, jx, depth + 1))}
                </div>
            )
        }

        return (
            <DropdownItem
                key={`${option.id}-${ix}`}
                onClick={() => onChange(option.id)}
            >
                <div style={{ paddingLeft: 8 * depth }}>{option.label}</div>
            </DropdownItem>
        )
    }

    return (
        <div key={title}>
            <InputGroup className="filter-input-group">
                <InputGroupText className="filter-key">
                    {title}
                </InputGroupText>
                <UncontrolledDropdown className="filter-dropdown-menu shadow-none" disabled={isDisabled}>
                    <DropdownToggle className="shadow-none" caret>
                        <span className="worktray-filters-btn-title">
                            {currentValue || t`Select`}
                        </span>
                    </DropdownToggle>
                    <DropdownMenu className="filter-dropdown-menu"  style={{ maxHeight: 300, overflowY: "scroll", width: "max-content" }}>
                        <DropdownItem key={0} onClick={() => onChange(null)}>
                            {NONE_VALUE}
                        </DropdownItem>
                        {(options || []).map((o, ix) => renderOption(o, ix))}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </InputGroup>
        </div>
    );
}

export function useParsedOptions(options, {
    parentId, id, label
}) {
    const tsOptions = useMemo(() => {
        if (!options) return [[], {}];

        const optionsById = {}
        const parsedOptions = options
            .map(item => {
                const node = makeTreeNode(item, id, label, parentId)
                optionsById[node.id] = node.label
                return node
            });

        if (parentId) {
            const rootNodes = organizeTreeNodes(parsedOptions);
            parsedOptions.sort((a, b) => a.visitIdx - b.visitIdx);
            return [rootNodes, optionsById];
        }

        return [parsedOptions, optionsById];
    }, [options, parentId]);

    return tsOptions;
}

export default LookupFilterField;