import Dashboard from "./Dashboard";
import DC from "./fields";
import TextDisplay from "./TextDisplay";
import WorkTray from "./WorkTray";
import WorkTrayIcon from "./WorkTrayIcon";
import EntityWidget from "./EntityWidget";
import EntitySummary from "./EntitySummary";

import ShiftScheduler from "./ShiftScheduler";
import ShiftViewer from "./ShiftViewer";
import CalendarViewer from "./CalendarViewer";
 
export const DASHBOARD_COMPONENTS = DC;
DASHBOARD_COMPONENTS.worktray = WorkTray;
DASHBOARD_COMPONENTS.entity = EntityWidget;
DASHBOARD_COMPONENTS.button = WorkTrayIcon;
DASHBOARD_COMPONENTS.text = TextDisplay;
DASHBOARD_COMPONENTS.summary = EntitySummary;
DASHBOARD_COMPONENTS.shift_scheduler = ShiftScheduler;
DASHBOARD_COMPONENTS.shift_viewer = ShiftViewer;
DASHBOARD_COMPONENTS.calendar_viewer = CalendarViewer;

DASHBOARD_COMPONENTS.location_shifts = ShiftScheduler;


export default Dashboard;