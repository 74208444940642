import { Button, Input, InputGroup, InputGroupText } from "reactstrap"
import { t } from "@lingui/macro";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const DateFilterField = (props) => {
    const {
        formData,
        formContext,
        formContext: {
            sideChannel
        },
        disabled,
        readonly: propReadonly,
        schema: {
            title,
            lookup,
        },
        idSchema: { $id } = {},
        uiSchema: {
            'ui:readonly': uiReadonly,
        },
        onChange,
    } = props;

    return (
        <div>
            <InputGroup className="filter-input-group">
                <InputGroupText className="filter-key">
                    {title}
                </InputGroupText>
                <DatePicker
                    value={dayjs(formData)}
                    className="filter-dropdown-menu shadow-none dropdown"
                    format="MM/DD/YYYY"
                    style={{
                        width: "130px", // Width for all dates is the same
                        height: "34px",
                        borderTopRightRadius: 20,
                        borderBottomRightRadius: 20,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        cursor: 'pointer'
                    }}
                    allowClear={false}
                    onChange={(date, v) => onChange(date.format("YYYY-MM-DD"))}
                    inputReadOnly
                />
            </InputGroup>
        </div>
    )
}

export default DateFilterField;
