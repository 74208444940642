import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import _ from 'lodash';
import { useEffect, useMemo } from "react";
import { useJnx } from "../../util/jnx";
import getPathFromId from '../../util/getPathFromId';
import { useSideChannelSubscription } from "../../util/useSideChannel";

const getLocalTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export default function MuiDatePicker({ 
    formData: value, 
    onChange, 
    uiSchema,
    idSchema: { $id } = {},
    formContext: {
        setFormDataValues,
        sideChannel
    },
}) {
    const {
        'ui:onSelect': onSelectExpr,
    } = uiSchema;

    const localTimeZone = useMemo(() => getLocalTimeZone(), []);

    const functionBinds = useMemo(() => ({ set: setFormDataValues }), [setFormDataValues]);
    const onSelectJnx = useJnx(onSelectExpr, { functionBinds });
    const rootFormData = useSideChannelSubscription(sideChannel, 0);
    const path = useMemo(() => getPathFromId($id), [$id]);

    useEffect(() => {
        if (onSelectJnx && rootFormData && path && value) {
            const timeoutId = setTimeout(() => {
                onSelectJnx.eval(rootFormData, '', { fieldPath: path, object: value });
            }, 500);

            return () => clearTimeout(timeoutId); 
        }
    }, [onSelectJnx, path, value]);


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                value={value ? dayjs(value) : null}
                onChange={newValue => onChange(newValue.format('YYYY-MM-DD'))}
                timezone={localTimeZone}
            />
        </LocalizationProvider>
    );
}