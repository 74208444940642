import React, { useMemo } from "react";
import { FormGroup, Input } from "reactstrap";
import useResourceLoader from '../../util/useResourceLoader';
import fetchApi from "../../util/fetchApi";
import checkErrors from "../../api/checkErrors";
import Notification from "../Notification";
import Loader from "../Loader";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import { getObject } from "../../util/mapObject";
import { useJnx } from "../../util/jnx";
import getPathFromId from '../../util/getPathFromId';


function StaticField(props) {
    const {
        formData,
        formContext: { sideChannel },
        disabled,
        idSchema: { $id } = {},
        schema: {
            title,
            lookup,
            type
        },
        uiSchema: {
            'ui:readonly': readonly,
            'ui:labelColor': labelColorExpr,
            'ui:class': uiClass
        },
        // title,
        required,
        onChange: propOnChange
    } = props;
    const {
        resource = "Options",
        api,
        params,
        id,
        label,
        noValue = ''
    } = lookup || {};

    const path = useMemo(() => getPathFromId($id), [$id]);
    const rootFormData = useSideChannelSubscription(sideChannel, 0);
    const paramsString = useMemo(() => (
        rootFormData && params ? Object.entries(params).map(([k, v]) => {
            if (v && v.field) v = getObject(rootFormData, v.field);
            return v ? `${k}=${encodeURIComponent(v)}` : null;
        }).filter(s => !!s).join("&") : ""
    ), [rootFormData, params]);

    const labelColorJnx = useJnx(labelColorExpr);
    const labelColor = useMemo(() => {
        return labelColorJnx?.eval(rootFormData, path)
    }, [labelColorJnx, path, rootFormData]);

    const [options, loadingOptions, errorLoadingOptions] = useResourceLoader(
        () => api ? fetchApi.lookup.get(`${api}${paramsString ? `?${paramsString}` : ''}`).then(checkErrors) : Promise.reject(new Error("Schema error: Missing lookup data")),
        [api, paramsString]
    );

    function onChange({ target: { value } }) {
        if (type === "number") {
            value = (value | 0);
        }
        propOnChange(value);
    }

    const circleStyle = {
        display: 'inline-block',
        width: '15px',
        height: '15px',
        backgroundColor: labelColor || 'black', // Default color is black
        borderRadius: '50%',
        marginRight: '5px', // Adjust the space between the circle and the text
    };

    return (
        <FormGroup disabled={readonly || disabled}>
            {title ? <><label className="control-label" htmlFor="root_preferredBranchId">
                {title}{required ? <span className="required">*</span> : null}
            </label></> : null}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {labelColor ? <><span style={circleStyle}/></> : null}
                <Input className={uiClass} plaintext value={formData}/>
            </div>
        </FormGroup>
    );
}


export default StaticField;