import { useMemo } from 'react';

/**
* A hook for obtaining all filters passed in to a dashboard in an object.
*
* It assumes the filters are located in a "filters" property of the dashboard props, and validates them.
*
* All filters must have a format of "{metadata|client}-{OPERATOR}-{PROPERTY}". 
* - metadata|client denotes that it must be the string "metadata" or "client". 
*   Clientside filters are used for filtering in the dashboard itself without an API call.
* - PROPERTY denotes a property of the main entity for a dashboard.
* - OPERATOR denotes the function which the backend will use for the filtering.
* 
* Assumptions:
* - Each filter key is unique... This may be too restrictive. This is the behavior implemented by WorkTray component.
*
* A filter is considered a client-side filter if its key starts with "client-"
*
* @todo Generalize the filter schema?
*
* @typedef {{key: string, title: string, type: string, format: string}} FilterSchema
* @typedef {{[key: string]: FilterSchema}} ValidatedFilters
*
* @param {{filters: Array<FilterSchema>}} element - The dashboard information, containing a filters array.
* @return {ValidatedFilters} - An object containing each validated key mapping to the original filter info.
*/
const useFilters = (
    element
) => {
    const { filters: propFilters } = element;
    const filters = useMemo(() => getFilters(propFilters), [element]);
    return filters;
};

const getFilters = (propFilters) => {
    if (!propFilters) {
        return {};
    }
    const schema = propFilters.reduce((acc, { key, ...obj }) => {
        if (validateFilterKey(key, key.startsWith('client-'))) {
            acc[key] = obj;
        }
        return acc;
    }, {});
    return schema;
}

const filterOperators = new Set([
    "like",
    "in",
    "not-in",
    "not-eq",
    "eq",
    "int-gt",
    "int-lt",
    "int-not-eq",
    "int-eq",
    "date-greaterthanorequal",
    "date-lessthanorequal",
]);

/**
 * Copied from UWE backend for consistency/accuracy.
 * 
 * Validates that a filter key has the right shape.
 * 
 * All available operators:
 * - "like"
 * - "in"
 * - "not-in"
 * - "not-eq"
 * - "eq"
 * - "int-gt"
 * - "int-lt"
 * - "int-not-eq"
 * - "int-eq"
 * - "date-greaterthanorequal"
 * - "date-lessthanorequal"
 * 
 * @param {string} key
 */
const validateFilterKey = (key, isClientSide) => {
    const keyArr = key.replace('.', '-').split('-');

    if (keyArr.length < 2 ) {
        console.log(`Filter validation failed for key ${key}: Not enough arguments provided.`)
        return false;
    }
    
    const prefix = keyArr.shift().toLowerCase();
    if (prefix === "client" && isClientSide) {
        return true;
    }
    else if (prefix !== "client" && isClientSide) {
        console.log(`Filter validation failed for key ${key}: Client-side filter does not match pattern "client*".`)
        return false;
    }
    else if (prefix === "client" && !isClientSide) {
        console.log(`Filter validation failed for key ${key}: Filter with pattern "client*" not marked as client-side.`)
        return false;
    }

    if (prefix !== "metadata") {
        console.log(`Filter validation failed for key ${key}: Filter does not match pattern "metadata-*".`)
        return false;
    }

    const metadataPropertyName = keyArr.pop();
    if (keyArr.length === 0) {
        console.log(`Filter validation failed for key ${key}: Found metadata property ${metadataPropertyName}, no operator provided.`)
        return false;
    }

    const operatorName = keyArr.join('-').toLowerCase();
    if (!filterOperators.has(operatorName)) {
        console.log(`Filter validation failed for key ${key}: Unrecognized operator ${operatorName}.`)
        return false;
    }

    return true;
}

export {
    useFilters
};


