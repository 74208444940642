import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { FormGroup } from "reactstrap";
import InputMask from 'react-input-mask';
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";

export default function DurationInput(props) {
    const {
        id,
        value,
        required: propRequired,
        disabled,
        readonly: propReadonly,
        autofocus,
        onChange: onChangeForm,
        idSchema: { $id },
        schema: { title },
        uiSchema: {
            'ui:titleAsLink': titleAsLink,
            'ui:hideOnEmpty': hideOnEmpty,
            'ui:readonly': uiReadonly,
            'ui:hideAttr': uiHideAttr,
            'akc:requiredIfVisible': requiredIfVisible
        },
        formContext,
        formData,
    } = props

    const readonly = uiReadonly || propReadonly;
    const required = !!propRequired || !!requiredIfVisible
    const [current, setCurrent] = useState("__:__");

    useDebouncedEffect(() => {
        if (current !== value) {
            if(current && current.length === 5){
                onChangeForm(convertToMs())
            }
        }
    }, [current], 450);

    useEffect(() => setCurrent(convertToDuration()), [formData]);

    const startWithTwo = current?.[0] === '2'

    const convertToMs = (() => {
        const [hours, minutes] = current.split(":").map(value => {
            if(value.includes("_")){
                return NaN
            }
            else{
                return parseInt(value, 10);
            }
        });
        const hoursToMs = hours * 60 * 60 * 1000;
        const minutesToMs = minutes * 60 * 1000;
        const totalMs = hoursToMs + minutesToMs;
        return totalMs;
    })

    const convertToDuration = (() => {
        if(typeof formData === "number"){
            const totalHours = Math.floor(formData / (1000 * 60 * 60));
            const totalMinutes = Math.floor((formData % (1000 * 60 * 60)) / (1000 * 60));
            const hours = String(totalHours).padStart(2, '0');
            const minutes = String(totalMinutes).padStart(2, '0');
            return `${hours}:${minutes}`;
        }
        return formData;
    })

    const mask = {
        'H' : "[0-2]",
        'h': startWithTwo ? '[0-3]' : '[0-9]',
        'M': "[0-5]",
        'm': "[0-9]"
    }

    return (
        <FormGroup>
            {title.trim() ? (<label className="control-label" htmlFor={$id}>
                {title}{required ? <span className="required">*</span> : null}
            </label>) : null}
            <InputMask
                mask='Hh:Mm'
                value={current}
                formatChars={mask}
                maskChar='_'
                disabled={disabled}
                className="form-control"
                required={required}
                onChange={(e) => setCurrent(e.target.value)}
                readOnly={readonly}
                autoFocus={autofocus}
                id={id}
            />
        </FormGroup>
    );
}