import { useEffect, useState,  useCallback, useMemo } from "react";
import { Trans } from "@lingui/macro";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupText,
} from 'reactstrap'
import { chunk, get, includes } from "lodash";
import { DatePicker } from "antd";

import dayjs from "dayjs";
import "dayjs/locale/en";

const NONE_LABEL = '--None--';

const createFilterCells = (filters, overrideOptions) => {
  if (!filters) {
    return [];
  }
  return Object.entries(filters).map(([key, f]) => {
    const filterTitle = f.title;
    const filterOptions = overrideOptions?.[f.title] || f.options || [];
    const filterValue = f.value || null;
    const picker = {
      key,
      title: filterTitle,
      value: filterValue,
      options: filterOptions
    };
    return picker;
  });
};

const getDefaultLabels = (filters, defaults, onChange) => {
  if (!filters) {
    return {};
  }
  const values = {};

  Object.entries(filters).forEach(([key, { title, value }]) => {
    values[title] = value;
  });

  if (defaults) {
    // Override the filter-provided default values for all filter titles
    Object.entries(defaults).forEach(([title, value]) => {
      if (title in values) {
        values[title] = value;
      }
    });
  }

  // None item
  values[NONE_LABEL] = null;

  return values;
};

function FilterGrid(props) {
  const { 
    filters, 
    cols = 3, 
    onChange,
    onInit,
    defaultLabels,
    defaultValues,
    options,
  } = props;

  // Get the data necessary for each filter cell from filters prop.
  const filterCells = useMemo(() => createFilterCells(filters, options), [filters, options]);

  // Keep track of state for each filter dropdown
  // This is an object with the titles as keys, that updates itself when something is selected
  const [labels, setLabels] = useState(getDefaultLabels(filters, defaultLabels));

  // Callback to do something with initial values on Init
  useEffect(() => {
    if (!onInit) {
      return;
    }
    const initialValues = [];
    Object.entries(filters).forEach(([key, { title, value }]) => {
      const entry = {key, title, value};
      if (defaultValues[title]) {
        entry.value = defaultValues[title];
      }
      initialValues.push(entry);
    });
    onInit(initialValues);
  }, []);

  const renderFilterCell = ({ title, options, key, label, value }) => {
    if (!title) return null;

    // Is Date if it can be validated as date or if it is bottom value and title includes 'date' in it.
    const isDate =
      dayjs(value, "YYYY-MM-DD", true).isValid() ||
      (!value && title.toLowerCase().includes("date"));

    const currentLabel = get(labels, title, null) || get(defaultLabels, title, null) || label;

    let dropdownClass = "filter-btn "
    if (isDate) dropdownClass += "filter-btn-date"

    const menuItems = options?.map((opt, i) => ({ key: i+1, label: opt.label, value: opt.value })) || [];

    const dropdownMenu = (
      <UncontrolledDropdown>
        <DropdownToggle className={dropdownClass} caret>
            {isDate ? (
              <DatePicker
                value={dayjs(currentLabel)}
                format="MM/DD/YYYY"
                style={{
                  width: "130px" // Width for all dates is the same
                }}
                allowClear={false}
                onChange={(date, v) => {
                  setLabels(prev => ({ ...prev, [title]: v }));   
                  onChange?.(key, title, v);
                }}
              />
            ) : (
              <span className="worktray-filters-btn-title">
                {currentLabel || title}
              </span>)
            }
        </DropdownToggle>
        {!isDate && (
          <DropdownMenu className="filter-dropdown-menu" style={{maxHeight: 300, overflowY: "scroll"}}>
            <DropdownItem key={0} onClick={() => {
              setLabels(prev => ({ ...prev, [title]: null }));      
              onChange?.(key, title, null);
            }}>
              {NONE_LABEL}  
            </DropdownItem>
              {menuItems.map(o => {
                  return (
                      <DropdownItem key={o.key} onClick={e => {
                        e.stopPropagation()
                        setLabels(prev => ({ ...prev, [title]: o.label }));            
                        onChange?.(key, title, o.value);
                      }}>
                          {o.label}
                      </DropdownItem>
                  )}
              )}
          </DropdownMenu>
        )}
      </UncontrolledDropdown>
    )
    
    if (currentLabel) {
      return (
        <div key={title}>
          <InputGroup className="filter-input-group">
              <InputGroupText className="filter-key">
                  {title}
              </InputGroupText>
              {dropdownMenu}
          </InputGroup>
        </div>
      )
    }

    if (options) {
      return (
        <div key={title}>
          {dropdownMenu}
        </div>
      )
    }

    return null
 }

  const renderRow = (row, ix) => {
    const cells = []
    for (let i = 0; i < cols; i += 1) {
      cells.push(row[i] || null)
    }
    return (
      <div
        key={`row-${ix}`}
        className="worktray-filters filter-grid-row"
        style={{ gridTemplateColumns: `repeat(${cols}, 1fr)`}}
      >
        {cells.map((cell, jx) => (
          <div key={`col-${ix}-${jx}`} className="filter-grid-cell">
            {cell ? renderFilterCell(cell) : null}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="filter-grid-container">
      <p style={{ color: "gray", fontSize: '10pt' }}><Trans>Filters:</Trans></p>
      <div className="filter-grid-filters" >
        {chunk(filterCells, cols).map((row, ix) => renderRow(row, ix))}
      </div>
    </div>
  );
}

/**
 * An object containing the dashboard object property schema for setting the input that this component expects
 * @type {Object}
 */
const filterGridPropertySchema = {
  "filters": {
      "title": "Filter Grid Configuration",
      ":classNames": "from-col-1-size-3",
      "type": "array",
      "map:array": "filters",
      "items": {
          "type": "object",
          "properties": {
              "title": {
                  "type": "string",
                  ":classNames": "from-col-1-size-1",
                  "title": "Title",
                  "map:field": "title"
              },
              "key": {
                ":classNames": "from-col-2-size-1",
                  "type": "string",
                  "title": "Key",
                  "map:field": "key"
              },
              "value": {
                ":classNames": "from-col-3-size-1",
                  "type": "string",
                  "title": "Default Value",
                  "map:field": "value",
              },
              "isClientSide": {
                ":classNames": "from-col-4-size-1",
                "type": "boolean",
                "title": "Is Client-Side?",
                "map:field": "isClientSide",
              },
              "options": {
                "title": "Options",
                ":classNames": "from-col-1-size-3",
                "type": "array",
                "map:array": "options",
                "items": {
                  "type": "object", 
                  "ui:removable": true,
                  "ui:addable": true,
                  "properties": {
                    "label": {
                      "type": "string",
                      "akc:requiredIfVisible": true,
                      "map:field": "label"
                    },
                    "value": {
                      "type": "string",
                      "akc:requiredIfVisible": true,
                      "map:field": "value"
                    }
                  }
                },
                "ui:hideTitle": true,
              }
          },
          "ui:positionButtons": "top",
          "structure": "Object",
          "ui:removable": true,
          "ui:addable": true
      },
      "ui:hideTitle": false,
    }
}

export { FilterGrid, filterGridPropertySchema }