import './scss/style.scss';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

import { I18nProvider } from '@lingui/react';
import i18n from './i18n';

import App from './App';
import registerServiceWorker from './registerServiceWorker';
import fetchApi from './util/fetchApi';
import { AuthProvider, getToken } from './context/AuthContext';

import { ENDPOINT, SENTRY_DSN } from './constants';
import { useZohoDeskWidget } from './util/useZohoDeskWidget';

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

fetchApi.configure({
  defaults: { baseurl: ENDPOINT },
  verbs: ['get', 'post', 'put', 'delete'],
  helpers: {
    api: (url, options, fetchApi) => fetchApi(url, {
      auth: { type: 'Bearer', token: getToken() },
      json: true,
      ...options
    }, fetchApi),
    external: (url, options, fetchApi) => fetchApi(url, {
      json: true,
      baseUrl: undefined,
      ...options
    }, fetchApi),
    lookup: (url, options, fetchApi) => fetchApi(`form-lookup/${url}`, {
      auth: { type: 'Bearer', token: getToken() },
      json: true,
      useCache(url, options) { return options.method === 'get'},
      ...options
    }, fetchApi)
  }
});


if (SENTRY_DSN) {
  Sentry.init({
      dsn: SENTRY_DSN,
      // integrations: [new BrowserTracing()],
      // tracesSampleRate: 1.0,
  });  
}

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');


function MainApp() {
  useZohoDeskWidget();

  return (
    <I18nProvider i18n={i18n}>
      <BrowserRouter basename={baseUrl}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </I18nProvider>
  )
}

const root = createRoot(rootElement);
root.render(<MainApp />);

registerServiceWorker();

