import React, { useCallback, useEffect, useState, useRef } from "react";
import { t } from "@lingui/macro";

import CodeMirror from '@uiw/react-codemirror';
import { keymap } from '@codemirror/view';
import { Prec } from "@codemirror/state";

import { indentUnit } from "@codemirror/language";

import { json } from '@codemirror/lang-json';
import { lintGutter, forEachDiagnostic } from "@codemirror/lint";

import JsonLinter from "./extensions/linter"
import BracketInserter from "./extensions/brackets";

import ToolbarButton from "./ToolbarButton";

function JsonEditor(props) {
    const { value, onChange, onReset, onSaveForm, maxHeight, buttons } = props;

    // ------------------------------
    // -- Form JSON state management
    // ------------------------------

    // The JSON string representation of the form when editor loaded
    const [initialFormJson, setInitialFormJson] = useState("");

    // The JSON string representation of the form (given in value prop)
    const [formJson, setFormJson] = useState("");

    // Flags
    const [isPretty, setIsPretty] = useState(true);
    const [hasError, setHasError] = useState(false);

    // Ref for the editor, to modify it from toolbar or other buttons.
    // Using workaround from:
    // https://github.com/uiwjs/react-codemirror/issues/314#issuecomment-1557816378
    const editorRef = useRef();
    const editorRefCallack = (editor) => {
      if (!editorRef.current && editor?.editor && editor?.state && editor?.view) {
        editorRef.current = editor; 
      }
    }

    // Parser for the JSON representation:
    // - Parse the JSON
    // - Pass parsed form object to onChange prop callback
    // In case of parse error, prints it to log.
    const parseFormJson = useCallback((formJson) => {
        try {
            const form = JSON.parse(formJson);
            onChange?.(form);
        } catch(e){
            console.log("Error parsing JSON: ", e.toString());
        }
    }, [onChange]);

    // Given the view, replace all contents with the reparsed form of content.
    const handlePrettify = useCallback(() => {
        const view = editorRef.current?.view;
        const prettifiedDocString = JSON.stringify(JSON.parse(view.state.doc.toString()), null, 4);
        view.dispatch({
            changes: {
                from: 0, 
                to: view.state.doc.toString().length, 
                insert: prettifiedDocString
            }
        })
        setIsPretty(true);
    }, [editorRef, setIsPretty]);

    // Given the view, replace all contents with the original value prop; call onReset.
    const handleReset = useCallback(() => {
        const view = editorRef.current?.view;
        view.dispatch({
            changes: {
                from: 0, 
                to: view.state.doc.toString().length, 
                insert: initialFormJson
            }
        })
        onReset?.();
    }, [editorRef, onReset, initialFormJson]);

    // Given the view, save contents to local storage; call onSaveForm
    const handleSave = useCallback(() => {
        onSaveForm?.();
    }, [editorRef, onSaveForm])

    // When form prop object changes, update the editor's JSON representation
    // Only when overwriting bottom value, otherwise, use internal codemirror state
    useEffect(() => {
        if (!formJson && value) {
            const text = JSON.stringify(value, null, 4);
            setFormJson(text);
        }
    }, [value]);

    // When editor loads, set the editor's initial JSON rep
    useEffect(() => {
        console.log("Loaded editor...");
        const text = JSON.stringify(value, null, 4);
        setInitialFormJson(text);
    }, []);

    // ---------------
    // -- Keybindings
    // ---------------
    // An alternative to the toolbar for calling the editor actions.

    // To determine platform specific keybinds
    const isMac = navigator.platform?.toLowerCase().includes('mac'); 
    const Mod = isMac ? 'Cmd' : 'Ctrl';

    // Maps certain key combinations to editor actions
    const keyMap = Prec.highest(
        keymap.of([
            {key: 'Mod-r', run: handleReset},
            {key: 'Mod-s', run: handleSave},
            {key: 'Mod-p', run: handlePrettify},
        ])
    );

    // Disables the default browser keybindings for keys used by the editor's custom keybindings!
    useEffect(() => {
        window.addEventListener("keydown", (e) => {
            const {key, metaKey, ctrlKey} = e; 
            if(
                ((!isMac && ctrlKey) || (isMac && metaKey)) && 
                (key === "r" || key === "s" || key === "p")
            ){
              e.preventDefault();
            }
        });
    }, []);

    // ------------
    // -- Toolbar
    // ------------
    // The slight delay to the buttons updating on errors does not cause any bugs, 
    // thanks to the protective programming which resets the json if it is saved 
    // with any syntax errors. Thanks Gio!
    const [isEditorOffset, setIsEditorOffset] = useState(false);

    const renderErrorIcon = () => {
        if (!hasError) {
            return null;
        }
        return (
            <i 
                className="fa fa-exclamation" 
                style={{ 
                    fontSize: '2rem', 
                    color: 'red', 
                    paddingTop: '10px',
                    paddingRight: '10px'
                }} 
            />
        );
    };

    const renderSaveButton = () => {
        if (!onSaveForm) {
            return null;
        }
        return (
            <ToolbarButton
                className="btn btn-success"
                iconClassName="fa fa-save"
                title={t`Save`} 
                onClick={handleSave}
                disabled={hasError}
                tooltip={`${Mod}-s`}
            />
        );
    };

    const renderExtraButtons = (buttons) => {
        return (buttons || []).map(({ color, icon, title, onClick, tooltip }, idx) => (
            <ToolbarButton
                key={idx}
                className={`btn btn-${color}`}
                iconClassName={icon}
                title={title}
                onClick={onClick}
                tooltip={tooltip}
            />
        ));
    };

    const renderResetButton = () => {
        return (
            <ToolbarButton 
                className="btn btn-dark"
                iconClassName="fa fa-undo"
                title={t`Reset`} 
                onClick={handleReset}
                tooltip={`${Mod}-r`}
            />
        );
    };

    const renderPrettifyButton = () => {
        return (
            <ToolbarButton
                iconClassName="fa fa-magic"
                title="Prettify" 
                onClick={handlePrettify}
                disabled={hasError || isPretty}
                tooltip={`${Mod}-p`}
            />
        );
    };

    const renderToolbar = () => {
        let style
        if (!isEditorOffset) {
            style = {position: 'sticky'};
        }
        else {
            style = {position: 'fixed', top: '77px', right: '26px'};
        }
        return (
        <div 
            style={{
                ...style,
                margin: 'auto',
                maxWidth: '50%',
                display: 'flex',
                gap: '2px',
                flexDirection: 'row-reverse',
                alignItems: 'auto',
                marginRight: '0px',
                // backgroundColor: '#FAFAFA',
                // padding: '1rem',
            }}
        >
            {renderResetButton()}
            {renderSaveButton()}
            {renderPrettifyButton()}
            {renderExtraButtons(buttons)}
            {renderErrorIcon()}
        </div>
        )
    };

    const handleScroll = () => {
        // Heuristically, 121px seems to be where icons start leaving the screen.
        setIsEditorOffset(window.scrollY > 121);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    // ----------
    // -- Editor
    // ----------

    // 1) Checks if linter found any diagnostics
    const onEditorUpdate = useCallback((viewUpdate) => {
        const state = viewUpdate.view.state;
        let errored = false;
        forEachDiagnostic(state, (diag, from, to) => {
            const msg = `${diag.severity}: ${diag.message}`;
            console.log(msg);
            errored = true;
        });

        setHasError(errored);
    }, [setHasError]);

    // Called when editor is changed: parse JSON representation 
    const onTextChange = useCallback((value, viewUpdate) => {
        setIsPretty(false);
        parseFormJson(value);
    }, [setIsPretty, parseFormJson]);

    return (
        <div 
            style={{
                position: 'relative',
                width:'100%',
                // height: `${height}px`
            }}
        >
            <CodeMirror
                ref={editorRefCallack} 
                value={formJson} 
                width="100%"
                maxHeight={maxHeight}
                extensions={[
                    keyMap,
                    indentUnit.of("    "),
                    json(), 
                    BracketInserter(),
                    ...(formJson ? [
                        JsonLinter(),
                        lintGutter()
                    ]: [])
                ]}
                onChange={onTextChange}
                onUpdate={onEditorUpdate} 
            />
            <div 
                style={{
                    position: 'absolute',
                    top: '2px',
                    right: '2px',
                }}
            >
                {renderToolbar()}
            </div>
        </div>
    )
}

export default JsonEditor;