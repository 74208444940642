import React, { useState, useCallback, useEffect } from 'react';

import { ColorPicker as AntdColorPicker } from 'antd';
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";


export default function ColorPicker(props) {
    const {
        id,
        className,
        value: propValue,
        required: propRequired,
        disabled,
        readonly: propReadonly,
        onChange: onChangeForm,
        idSchema: { $id },
        uiSchema: {
            'ui:readonly': uiReadonly,
            'akc:requiredIfVisible': requiredIfVisible
        },
        formData,
    } = props;

    const value = propValue || formData || '#000000';
    const readonly = uiReadonly || propReadonly;
    const required = !!propRequired || !!requiredIfVisible

    return (
        <AntdColorPicker
            onChangeComplete={(color) => {
                onChangeForm(color.toHexString());
            }}
            value={value}
            disabled={disabled || readonly}
            disabledAlpha
            showText
        />
    );
}