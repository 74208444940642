import React from "react";
import classnames from "classnames";
import {
    Button,
    InputGroup,
    InputGroupText, 
} from "reactstrap";

function CheckboxFilterField(props) {
    const {
        formData,
        disabled,
        idSchema: { $id },
        schema: {
            title,
            color = 'secondary',
            checkedColor = 'success'
        },
        uiSchema: {
            'ui:readonly': uiReadonly,
            'ui:checkboxHidden': checkboxHidden,
        },
        required,
        readonly: propReadOnly,
        onChange: propOnChange
    } = props;

    const readonly = uiReadonly || propReadOnly;

    function onChange() {
        propOnChange(!formData);
    }

    return (
        <div key={title}>
            <InputGroup className="filter-input-group">
                <label htmlFor={$id}>
                    <InputGroupText className="filter-key">
                        {title}
                    </InputGroupText>
                </label>
                <Button
                    outline
                    className={classnames("filter-checkbox shadow-none", {
                        "filter-checkbox-checked": formData,
                    })}
                    onClick={onChange}
                >
                    {formData ? '\u2611' : '\u25A1'}
                </Button>
            </InputGroup>
        </div>
    );
}

export default CheckboxFilterField;