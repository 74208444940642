import { Button, Input, InputGroup, InputGroupText } from "reactstrap"
import { t } from "@lingui/macro";
import { useRef } from "react";

const StringFilterField = (props) => {
    const {
        formData,
        formContext,
        formContext: {
            sideChannel
        },
        disabled,
        readonly: propReadonly,
        schema: {
            title,
            lookup,
        },
        idSchema: { $id } = {},
        uiSchema: {
            'ui:readonly': uiReadonly,
        },
        onChange,
    } = props;

    const inputRef = useRef();

    return (
        <div>
            <InputGroup className="filter-input-group">
                <InputGroupText className="filter-key">
                    {title}
                </InputGroupText>
                <Input
                    innerRef={inputRef}
                    className="filter-input shadow-none"
                    value={formData}
                    placeholder={t`Filter by...`}
                    onChange={e => onChange(e.target.value)}
                />
                <Button
                    outline
                    className="filter-delete shadow-none"
                    onClick={() => {
                        onChange("");
                        inputRef.current.focus();
                    }}
                >
                    &#10005;
                </Button>
            </InputGroup>
        </div>
    )
}

export default StringFilterField;
