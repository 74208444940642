import React, {useMemo} from "react";
import { Trans } from "@lingui/macro";
import {
    Input,
    Pagination as RSPagination,
    PaginationItem,
    PaginationLink
} from 'reactstrap';


function Pagination({
    offset, setOffset,
    size, setSize,
    sizes=[10, 20, 30, 50, 80],
    count,
    maxPages=5,
}){
    const paging = useMemo(() => {
        const N = Math.max(size, 1);
        const totalPages = Math.ceil(count / N);
        const lastPage = totalPages - 1;
        const currentPage = Math.floor((offset + 1) / N);

        const pageStart = Math.max(0, (currentPage - maxPages / 2) | 0);
        const pageEnd = Math.min(pageStart + maxPages, totalPages - 1) + 1;

        const pages = [];
        for (let page = pageStart; page < pageEnd; page += 1) {
            pages.push({ page, offset: page * size});
        }

        const isFirst = currentPage === 0;
        const isLast = currentPage === lastPage;

        return {
            isFirst, isLast,
            pages,
            currentPage,
            previousPage: {page: (currentPage - 1), offset: (currentPage - 1) * size},
            nextPage: {page: (currentPage + 1), offset: (currentPage + 1) * size},
            firstPage: {page: 0, offset: 0},
            lastPage: {page: lastPage, offset: lastPage * size},
        }
    }, [offset, count, size, maxPages]);

    const {
        isFirst, isLast, pages,
        currentPage,
        previousPage, nextPage,
        firstPage, lastPage,
    } = paging;

    return (
        <div style={{ marginTop: 10, display: 'flex', gap: 20 }}>
            <RSPagination>
                <PaginationItem disabled={isFirst}><PaginationLink first    onClick={() => setOffset(firstPage.offset)}/></PaginationItem>
                <PaginationItem disabled={isFirst}><PaginationLink previous onClick={() => setOffset(previousPage.offset)}/></PaginationItem>
                {(pages || []).map(({page, offset}) => (
                    <PaginationItem key={offset} active={page === currentPage}>
                        <PaginationLink onClick={() => setOffset(offset)}>{page + 1}</PaginationLink>
                    </PaginationItem>
                ))}
                <PaginationItem disabled={isLast} ><PaginationLink next     onClick={() => setOffset(nextPage.offset)}/></PaginationItem>
                <PaginationItem disabled={isLast} ><PaginationLink last     onClick={() => setOffset(lastPage.offset)}/></PaginationItem>
            </RSPagination>
            <span style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <small><Trans>Results per page</Trans></small>
                <Input type="select" style={{width: 'auto', height: 'auto'}} value={size} onChange={({target: {value}}) => setSize(value | 0)}>{sizes.map(size => (
                    <option key={size}>{size}</option>
                ))}</Input>
            </span>
        </div>
    )
}


export default Pagination;