import React, { useState, useContext } from 'react';
import {
    Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavbarText
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { AuthContext } from '../context/AuthContext';
import { Logo } from "./_general/Logo";

import Clock from './Clock';
import AppContext from '../context/AppContext';
import { LABEL } from '../LanguageConstant';
import NavNotificationPanel from './NavNotificationPanel/NavNotificationPanel';


function NavMenu({ logoOnly }) {
    const auth = useContext(AuthContext);
    const user = auth?.user?.user;
    const permissions = ((auth || {}).user || {}).permissions || {};
    const [collapsed, setCollapsed] = useState(true);
    function toggleNavbar() {
        setCollapsed(!collapsed);
    }
    const { navbarContent } = useContext(AppContext);

    const manageAnything = permissions.ManageUsersInUnit || permissions.ManageOrganizationalUnits;

    return (
        <header><Navbar
            className="navbar-toggleable-sm gap-x"
            fixed="top"
            expand="sm"
        >
            <NavbarBrand className="nav-logo-icon navbar-nav" tag={Link} to="/">
                <span className="nav-logo-container">
                    <Logo width={47} height={47} />
                    <span className="nav-logo-title">
                        Shift Scheduler
                    </span>
                </span>
            </NavbarBrand>

            {
                user && <Link
                    style={{ backgroundColor: "#FF9A0E", borderColor: "#FF9A0E" }}
                    className="btn btn-primary worktray-action-btn"
                    to="/workflow/job/new"
                >
                    Create Shift +
                </Link>
            }

            {logoOnly ? null : (
                <>
                    <NavbarToggler onClick={toggleNavbar} className="mr-2 hide-when-mobile" />
                    {auth.user ? (<span className="nav-notification-mobile"><NavNotificationPanel /></span>) : null}

                    <Collapse isOpen={!collapsed} navbar>
                        <Nav className="navbar-nav justify-content-center">
                            <NavbarText>
                                <Clock className="nav-clock" />
                            </NavbarText>
                            {navbarContent || null}
                        </Nav>

                        <Nav className="navbar-nav">
                            {manageAnything ? (
                                <NavItem>
                                    <NavLink tag={Link} to="/system-settings">
                                        <i className="fa fa-cog nav-icon" />
                                    </NavLink>
                                </NavItem>
                            ) : null}

                            {auth.user ? (<NavItem><NavNotificationPanel /></NavItem>) : null}

                            {/* {auth.user ? (<NavbarText className="d-flex align-items-center">
                                <NavLink tag={Link} to="/support"><span className="navbar-help-text">{LABEL.help}</span></NavLink>
                            </NavbarText>) : null} */}
                            {auth.user ? (
                                <NavItem>
                                    <UncontrolledDropdown className="user-nav-dropdown">
                                        <DropdownToggle caret nav>
                                            <i className="fa fa-user nav-icon" />
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem className="user-info d-flex align-items-center" tag={Link} to="/profile" >
                                                <UserDataPane icon={auth?.user?.actualUser ? "user-secret" : ""} user={auth.user} />
                                            </DropdownItem>
                                            {auth?.user?.actualUser ? (<>
                                                <hr className="dropdown-divider" />
                                                <DropdownItem className="user-info d-flex align-items-center" >
                                                    <UserDataPane icon="user" user={auth.user.actualUser} />
                                                </DropdownItem>
                                                <hr className="dropdown-divider" />
                                                <DropdownItem className="d-flex align-items-center" tag={Link} to="/" onClick={() => auth.stopImpersonation()}>
                                                    Dejar de Impersonar
                                                </DropdownItem>
                                            </>) : null}
                                            <hr className="dropdown-divider" />
                                            <DropdownItem className="d-flex align-items-center" tag={Link} to="/faq">
                                                Frequently Asked Questions
                                            </DropdownItem>
                                            <hr className="dropdown-divider" />
                                            <DropdownItem className="d-flex align-items-center" tag={Link} to="/" onClick={() => auth.logout()}>
                                                Log Out
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </NavItem>
                            ) : null}
                        </Nav>
                    </Collapse>
            </>)}
        </Navbar></header>
    );
}


function UserDataPane({ user, icon }) {
    const {
        firstName,
        lastName,
        organizationalUnit,
        accessProfile,
        roles,
    } = user?.user || {};

    return (<div>
        {icon ? <i className={`fa fa-${icon}`} /> : null}
        <div className="user">
            {` ${firstName || ""}  ${lastName || ""}`}
        </div>
        {organizationalUnit ? (
            <div className="organization">{organizationalUnit?.code}</div>
        ) : null}
        {accessProfile ? (
            <div className="role">{accessProfile?.displayName || "----"}</div>
        ) : null}
        {(roles || []).map((ur, index) => (
            <div key={index} className="role">{ur?.role?.name}</div>
        ))}
    </div>);
}


export default NavMenu;
