import React, { useMemo, useEffect } from "react";
import { FormGroup, Input } from "reactstrap";
import { useJnx } from "../../util/jnx";
import getPathFromId from '../../util/getPathFromId';
import { useSideChannelSubscription } from "../../util/useSideChannel";


function CheckboxToggleButtonField(props) {
    const {
        formData,
        formContext,
        disabled,
        idSchema: { $id },
        schema: {
            title,
            color = 'secondary',
            checkedColor = 'success'
        },
        uiSchema: {
            'ui:readonly': uiReadonly,
            'ui:readonlyExpr': uiReadonlyExpr,
            'ui:checkboxHidden': checkboxHidden,
            'ui:onChange': onChangeExpr,
            'ui:class': uiClass
        },
        required,
        // readonly: propReadOnly,
        onChange: propOnChange
    } = props;

    const {
        setFormDataValues,
        sideChannel,
        bindings: fcBindings,
        // openModal,
    } = formContext;

    const functionBinds = useMemo(() => ({ set: setFormDataValues }), [setFormDataValues]);
    const onChangeJnx = useJnx(onChangeExpr, { functionBinds });
    const path = useMemo(() => getPathFromId($id), [$id]);
    const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}, {}];
    const bindings = useMemo(() => ({
        ...fcBindings,
        formObject,
        rootFormData,
        formContext,
    }), [rootFormData, fcBindings, formObject, formContext]);

    const readOnlyJnx = useJnx(uiReadonlyExpr);
    const readOnlyMemo = useMemo(() => {
        return readOnlyJnx?.eval(rootFormData, path, bindings)
    }, [readOnlyJnx, path, rootFormData, bindings]);

    const readonly = readOnlyMemo || uiReadonly;

    const currentColor = ((formData && checkedColor) ? checkedColor : color) || 'secondary';

    function onChange({ target: { value, checked } }) {
        propOnChange(checked);

        if (onChangeJnx && rootFormData && path) {
            const timeoutId = setTimeout(() => {
                onChangeJnx.eval(rootFormData, path, { fieldPath: path, object: checked, bindings });
            }, 500);

            return () => clearTimeout(timeoutId); 
        }
    }

    return (
        <FormGroup
            disabled={readonly || disabled}
            check
            className="checkbox-toggle-button-field"
        >
            <label
                className={`control-label checkbox btn btn-${currentColor} ${checkboxHidden ? 'checkbox-hidden' : ''} ${formData ? 'checked' : ''} ${uiClass}`}
                htmlFor={$id}
            >
                <Input
                    id={$id}
                    type="checkbox"
                    disabled={readonly || disabled}
                    required={required}
                    checked={!!formData}
                    onChange={onChange}
                />
                {title}
            </label>
        </FormGroup>
    );
}

export default CheckboxToggleButtonField;