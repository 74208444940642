import React, { useMemo } from "react";
import { t } from "@lingui/macro";
import {
  InputGroup,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  InputGroupText,
  DropdownToggle
} from "reactstrap";

const NONE_VALUE = "--None--";

function DropdownFilterField(props) {
  const {
    formData,
    formContext: { sideChannel },
    disabled,
    readonly: propReadonly,
    schema: { title, options },
    idSchema: { $id } = {},
    uiSchema: { "ui:readonly": uiReadonly },
    onChange
  } = props;

  const readonly = propReadonly || uiReadonly;

  const isDisabled = readonly || disabled;

  const currentValue = useMemo(() => {
    if(formData !== undefined && options.length >= 1 && typeof(options) === 'object'){
        const variable = options.find(item => item[0] === formData);
        return variable?.length >= 2 ? variable[1] : formData
    }
    return formData;
  }, [formData]);

  const renderOption = (option, ix) => {
    if(typeof(option) === 'object'){
      return (
        <DropdownItem key={`${option[0]}-${ix}`} onClick={() => onChange(option[0])}>
          {option[1]}
        </DropdownItem>
      );
    }
    
    return (
      <DropdownItem key={`${option}-${ix}`} onClick={() => onChange(option)}>
        {option}
      </DropdownItem>
    );
  };

  return (
    <div key={title}>
      <InputGroup className="filter-input-group">
        <InputGroupText className="filter-key">{title}</InputGroupText>
        <UncontrolledDropdown
          className="filter-dropdown-menu shadow-none"
          disabled={isDisabled}
        >
          <DropdownToggle className="shadow-none" caret>
            <span className="worktray-filters-btn-title">
              {currentValue || t`Select`}
            </span>
          </DropdownToggle>
          <DropdownMenu className="filter-dropdown-menu">
            <DropdownItem key={0} onClick={() => onChange(null)}>
              {NONE_VALUE}
            </DropdownItem>
            {(options || []).map((o, ix) => renderOption(o, ix))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </InputGroup>
    </div>
  );
}

export default DropdownFilterField;
